import { useEffect, useState } from 'react';

const useIntersection = (ref, customOptions = {}) => {
  const [intersect, setIntersect] = useState(false);

  useEffect(() => {
    const element = ref.current;
    const options = { threshold: 1, ...customOptions };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersect(entry.isIntersecting);
          observer.unobserve(element);
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return intersect;
};

export default useIntersection;
