import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import useIntersection from '../../hooks/intersection';
import './AngebotStage.scss';

const AngebotStage = ({ title, description, color, image }) => {
  const box = useRef();
  const isIntersecting = useIntersection(box);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  return (
    <header
      className={
        'AngebotStage AngebotStage--' +
        color +
        (isIntersecting && isImgLoaded ? ' is-intersecting' : '')
      }
      ref={box}
    >
      <div className="inner-wrapper">
        <div className={'AngebotStage__wrapper'}>
          <div className={'AngebotStage__text'}>
            <p className={'h1 AngebotStage__title'}>{title}</p>
            <p className={'AngebotStage__subhead'}>{description}</p>
          </div>
          <div className={'AngebotStage__image'}>
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className={'AngebotStage__image-wrapper'}
              onLoad={() => setIsImgLoaded(true)}
            />
            <svg viewBox="0 0 20 20" className={'AngebotStage__circle'}>
              <circle className={'circle--1'} r="7" cx="10" cy="10" />
              <circle className={'circle--2'} r="7.5" cx="10" cy="10" />
            </svg>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AngebotStage;
